import { createResource, createSignal, onMount, Show } from "solid-js";
import { getGiftById } from "~/server/apis/client_apis";
import { GiftCardDetails } from "~/server/types/pluto";
import { PhosphorIcon } from "~/widgets/icons";
import { formatDateLong } from "~/utils/date";
import { closeIconSM, giftWhiteIcon } from "~/assets/assets";
import { toRupees } from "~/utils/number";
import { ThreeDotLoader } from "~/widgets/button";
import { GiftCard } from "../gift-card/gift_card";

export function WelcomeBackModal(props: {
  closeModal: () => void;
  pendingGiftId: string;
}) {
  const [animate, setAnimate] = createSignal(false);

  onMount(() => {
    setAnimate(true);
  });

  const [response] = createResource<GiftCardDetails | undefined>(async () => {
    let response: GiftCardDetails;
    try {
      response = await getGiftById(props.pendingGiftId);
      return response;
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <div
      class="fixed inset-0 z-50 flex h-full flex-col items-center justify-start bg-black opacity-0 transition-opacity duration-300"
      classList={{
        "opacity-100": animate(),
      }}
    >
      <div class="relative flex min-h-14 w-full items-center justify-center bg-plutoHeaderBg bg-cover bg-no-repeat px-4 py-2">
        <button onClick={props.closeModal} class="absolute left-4 top-4 cursor-pointer">
          <img src={closeIconSM} alt="close" />
        </button>
        <p class="text-center text-smallBold uppercase tracking-[1.1px] text-white">
          Welcome back
        </p>
        <button
          onClick={props.closeModal}
          class="absolute right-4 top-4 hidden cursor-pointer items-center justify-center gap-2 lg:flex "
        >
          <img src={giftWhiteIcon} alt="gift icon" />
          <p class="text-buttonMedium text-white underline">Create new gift</p>
        </button>
      </div>
      <div class="relative h-full w-full overflow-y-scroll bg-black pt-2 text-white">
        <Show
          when={response()}
          fallback={
            <div class="flex h-[92vh] w-full items-center justify-center">
              <ThreeDotLoader color="#fff" />
            </div>
          }
        >
          <div class="mx-auto lg:w-1/4 my-6 w-full px-8 text-center text-h3 text-white">
            <Show
              when={response()?.paymentStatus === "PAID"}
              fallback={
                <>
                  Load money & share <br />
                  {response()?.content.text.occasion.recipientName}’s gift
                </>
              }
            >
              {response()?.content.text.occasion.recipientName}’s gift worth{" "}
              {toRupees(response()!.budget)} is ready to share
            </Show>
          </div>
          <GiftCard
            giftDetails={() => response()!}
            flipOnCard={true}
            showActions={true}
            showNavigationDots={true}
            showCenterHeader={true}
            generatedOnDate={formatDateLong(new Date(response()!.generatedOn))}
          />
          <div class="p-4 pt-0 lg:hidden">
            <button
              onClick={props.closeModal}
              class="flex h-11 w-full cursor-pointer items-center justify-center gap-1 rounded-[41px] border border-baseSecondaryDark bg-transparent px-3 "
            >
              <img src={giftWhiteIcon} alt="gift icon" />
              <p class="pt-1 text-buttonMedium font-bold text-white">
                Create new gift
              </p>
            </button>
          </div>
        </Show>
      </div>
    </div>
  );
}
