import { createEffect, createSignal, onMount, Show } from "solid-js";
import { WelcomeBackModal } from "../modals/welcome_back_modal";
import { goBack } from "~/shared_states/modal";
import PlutoChatComponent from "./pluto_chat_component";

export type PlutoNewChatProps = {
  pendingGiftId: string | undefined;
};

export function PlutoNewChatComponent(props: PlutoNewChatProps) {
  const [isFullScreenModalOpen, setIsFullScreenModalOpen] = createSignal(false);
  const [isWelcomModalOpen, setIsWelcomModalOpen] = createSignal(false);

  onMount(async () => {
    listenToPopEvents();
    if (props.pendingGiftId) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsWelcomModalOpen(true);
    }
  });

  function listenToPopEvents() {
    window.onpopstate = () => {
      if (isFullScreenModalOpen()) {
        setIsFullScreenModalOpen(false);
      } else if (isWelcomModalOpen()) {
        setIsWelcomModalOpen(false);
      }
    };
  }

  createEffect(() => {
    if (isFullScreenModalOpen() || isWelcomModalOpen()) {
      window.history.pushState(null, ""); // pushing an empty state when page is pushed
    }
  });
  return (
    <>
      <PlutoChatComponent
        isFullScreenModalOpen={isFullScreenModalOpen}
        setIsFullScreenModalOpen={setIsFullScreenModalOpen}
      />
      <Show when={isWelcomModalOpen()}>
        <WelcomeBackModal
          pendingGiftId={props.pendingGiftId!}
          closeModal={() => {
            goBack();
          }}
        />
      </Show>
    </>
  );
}
