import { useLocation } from "@solidjs/router";
import { ModalState } from "~/components/pluto/chat/pluto_chat_component";
import { PlutoNewChatComponent } from "~/components/pluto/chat/pluto_new_chat_component";

export default function PlutoNewChatRoute() {
  const location = useLocation<ModalState>();

  return (
    <PlutoNewChatComponent pendingGiftId={location.state?.id} />
  );
}
